// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import './variables.scss';

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($mycareplan-theme);

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');

html,
body {
  height: 100%;
}
body {
  margin: 10px;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background: #fff !important;
}

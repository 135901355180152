/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/


/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import './variable.scss';
//@import './icons/themify-icons/themify-icons.css';
//@import './icons/font-awesome/css/font-awesome.css';
@import './spinner.scss';
@import './app.scss';
@import './header.scss';
@import './widgets.scss';
@import './pages.scss';
@import './sidebar.scss';
@import './dark-sidebar.scss';
@import './responsive.scss';
@import './rtl.scss';
// Red Theme
.danger {
    @import './colors/red.scss';
    @include angular-material-theme($red-app-theme);
}

// Blue Theme
.blue {
    @import './colors/blue.scss';
    @include angular-material-theme($blue-app-theme);
}

// Green Theme
.green {
    @import './colors/green.scss';
    @include angular-material-theme($green-app-theme);
}

// Dark Theme
.dark {
    @import './colors/dark.scss';
    @include angular-material-theme($dark-theme);
}

$custom-typography: mat-typography-config( $font-family: 'Poppins');
@include mat-core($custom-typography);